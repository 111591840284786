<script>
import Layout from "../../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Add Flight",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Add Flight",
      items: [],
      value: null,
      options_vendor: [
        "Air Asia",
        "Citilink",
        "Garuda",
        "Lion Air",
        "Sriwijaya",
        "Tri-MG"
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

<div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Vendor"
                    label-for="vendflight_vendorid"
                  >
                    <multiselect v-model="value" :options="options_vendor"></multiselect>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Name"
                    label-for="vendor_name"
                  >
                    <b-form-input id="vendor_name" name="vendor_name" value=""></b-form-input>
                  </b-form-group>

                  
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>